<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <b-row class="no-gutters p-3">
      <b-button class="ml-auto" @click="createUserAccount">
        {{ $t("button.addUser") }}
      </b-button>
    </b-row>
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <user-list />
    </b-col>
  </div>
</template>

<script>
import UserList from "./../components/User/UserList";
export default {
  components: {
    UserList
  },
  data() {
    return {
      pageTitle: "page.users.title"
    };
  },
  methods: {
    createUserAccount() {
      this.$router.push({ name: "CreateUserAccount" });
    }
  }
};
</script>

<style scoped></style>
