<template>
  <b-card no-body class="p-3 mb-2">
    <b-row class="no-gutters align-items-center">
      <b-col class="col-auto mr-3">
        <img src="./../../assets/icons/user.svg" class="user__icon" />
      </b-col>
      <b-col>
        <div class="font-weight-bold">
          {{ userFullName }}
        </div>
        <div class="small">
          <span class="mr-3">{{ user.registration.email }}</span>
          <span>{{ user.phone }}</span>
        </div>
      </b-col>
      <b-col class="col-auto">
        <b-button size="sm" variant="outline-primary" @click="viewUserProfil">
          {{ $t("button.details") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    userFullName() {
      return this.user.firstName + " " + this.user.lastName;
    }
  },
  methods: {
    viewUserProfil() {
      this.$router.push({
        name: "EditUserAccount",
        params: { id: this.user._id }
      });
    }
  }
};
</script>

<style scoped>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
