<template>
  <div>
    <div v-for="user in userList" :key="user.id">
      <user-list-item :user="user" />
    </div>
    <div v-if="isEmptyList">
      <h5 class="text-center">{{ $t("emptyList") }}</h5>
    </div>
  </div>
</template>

<script>
import UserListItem from "./UserListItem";
export default {
  components: {
    UserListItem
  },
  computed: {
    userList() {
      return this.$store.getters["employees/getEmployeeList"];
    },
    isEmptyList() {
      return this.userList.length === 0;
    }
  }
};
</script>

<style scoped></style>
